import { memo } from 'react'
import Link from 'next/link'
import Template from '../containers/Template/Template'

const Custom404 = () => (
  <Template
    title="Sidan existerar inte.. | Jiroy"
    description="Det du söker, är tyvärr borta"
    indexed={false}
    followed={false}
    hideFooter
  >
    <main>
      <div>

        <div className="error-404">
          <div className="container">
            <div>
              <h1>404</h1>
              <p>
                Det du söker,
                <br />
                är tyvärr borta
              </p>
            </div>

            <Link href="/">
              { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
              <a className="button-cta">Gå till startsidan</a>
            </Link>

          </div>
        </div>
      </div>
    </main>
  </Template>
)

export default memo(Custom404)
